import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Image from "~/components/image";
import Card from "~/components/styled/card";

const DestinationList = ({ destinations }) => (
	<div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-20">
		{destinations.map(({ node }) => {
			return (
				<Card key={node.id}>
					<Link to={`/destinations/${node.slug}`}>
						<Image
							alt="Category Image"
							className="rounded-t-md transform border-gray-200	 border-b"
							image={node.image}
							loading="lazy"
						/>
						<p className="px-4 py-6 font-medium">{node.name}</p>
					</Link>
				</Card>
			);
		})}
	</div>
);

DestinationList.propTypes = {
	destinations: PropTypes.array,
};

export default DestinationList;
