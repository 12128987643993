import React from "react";
import { graphql } from "gatsby";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import PageHeading from "~/components/styled/page-heading";
import DestinationList from "~/components/destination-list";
import { Link } from "gatsby";
import { ChevronRightIcon } from "@heroicons/react/outline";

const DestinationsPage = ({ data }) => {
	const destinations = data.allStrapiDestination.edges;
	// const seo = { title: "Destinations" }
	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title="All Destinations"
				description="Discover the best of India and Asia on a bicycle tour or cycling holiday."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-US",
				// 		href: "https://www.artofbicycletrips.com/destinations",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/destinations",
				// 	},
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/destinations",
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: "https://www.artofbicycletrips.com/destinations",
				// 	},
				// ]}
			/>

			{/* Breadcrumbs */}
			<div className="my-4 flex items-center capitalize whitespace-nowrap overflow-x-auto">
				<Link className="text-xs" to={`/`}>
					Home
				</Link>
				<ChevronRightIcon className="h-4 w-4 text-gray-300 mx-1" />
				<span className="text-xs">Destinations</span>
			</div>

			<PageHeading>All Destinations</PageHeading>
			<DestinationList destinations={destinations} />
		</Layout>
	);
};

export const query = graphql`
	query DestinationsQuery {
		allStrapiDestination(filter: { locale: { eq: "en" } }) {
			edges {
				node {
					name
					id
					slug
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.3)
							}
						}
					}
				}
			}
		}
	}
`;

export default DestinationsPage;
